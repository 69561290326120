import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /**
     * PERSONAL DATA STORE
     */
    personalData: {
      // LEARN
      learn: [
        // Meet the experts
        // {
        //   name: 'Meet the Expert',
        //   category: 'Video',
        //   route: '01'
        // },

        // How do we define personal resilience?
        {
          name: 'How do we define personal resilience?',
          category: 'Video',
          route: '02'
        },

        // Multisystemic model of resilience
        {
          name: 'Multisystemic model of resilience',
          category: 'Toolkit post',
          route: '03'
        },

        // Micro resilience
        {
          name: 'Micro resilience',
          category: 'Toolkit post',
          route: '04'
        },

        // Five Factor Model of Resilience
        {
          name: 'Five Factor Model of Resilience',
          category: 'Toolkit post',
          route: '05'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '06'
        }
      ],

      // DISCUSS
      discuss: [
        // Boosting good habits
        {
          name: 'Boosting good habits',
          category: 'Video',
          route: '01'
        },

        // Greatest strengths
        {
          name: 'Greatest strengths',
          category: 'Multiple',
          route: '02'
        },

        // Sleep on it
        {
          name: 'Sleep on it',
          category: 'Video',
          route: '03'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '04'
        }
      ],

      // SHARE
      share: [
        // How resilient are you?
        {
          name: 'How resilient are you?',
          category: 'Quiz',
          route: '01'
        },

        // A flash of resilience
        {
          name: 'A flash of resilience',
          category: 'Document',
          route: '02'
        },

        // Less stress in 3 minutes
        {
          name: 'Less stress in 3 minutes',
          category: 'Video',
          route: '03'
        },

        // 26 weekly activities to help students through the school year
        {
          name: '26 weekly activities to help students through the school year',
          category: 'Document',
          route: '04'
        },

        // Learning to love being a student
        {
          name: 'Learning to love being a student',
          category: 'Website',
          route: '05'
        },

        // Thriving in Action Online Faculty and Staff Guide
        {
          name: 'Thriving in Action Online Faculty and Staff Guide',
          category: 'Website',
          route: '06'
        },

        // Thriving in Action Online Student Guide
        // {
        //   name: 'Thriving in Action Online Student Guide',
        //   category: 'Website',
        //   route: '07'
        // },

        // Writing for wellness
        {
          name: 'Writing for wellness',
          category: 'Toolkit post',
          route: '08'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '09'
        }
      ],

      // DESIGN
      design: [
        // The importance of mindset
        {
          name: 'The importance of mindset',
          category: 'Multiple',
          route: '01'
        },

        // Providing feedback that encourages resilience
        {
          name: 'Providing feedback that encourages resilience',
          category: 'Document',
          route: '02'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '03'
        }
      ],

      // REVIEW
      review: [
        // Take a deep dive into positive psychology
        {
          name: 'Take a deep dive into positive psychology',
          category: 'Website',
          route: '01'
        },

        // “Boosting Student Resilience with Dr. Diana Brecher”
        {
          name: '“Boosting Student Resilience with Dr. Diana Brecher”',
          category: 'Podcast',
          route: '02'
        },

        // The power of “flow”
        {
          name: 'The power of “flow”',
          category: 'Video',
          route: '03'
        },

        // Works cited
        {
          name: 'The science of flow',
          category: 'Journal article',
          route: '04'
        },
        // Works cited
        {
          name: 'Research on habits',
          category: 'Journal article',
          route: '05'
        },
        // Works cited
        {
          name: 'Research on sleep',
          category: 'Journal article',
          route: '06'
        },
        // Works cited
        {
          name: 'Research on character strengths',
          category: 'Journal article',
          route: '07'
        },
        // Works cited
        {
          name: 'Works cited',
          category: 'Journal article',
          route: '08'
        }
      ]
    },

    /**
     * ACADEMIC DATA STORE
     */
    academicData: {
      // LEARN
      learn: [
        // Meet the expert
        // {
        //   name: 'Meet the Expert',
        //   category: 'Video',
        //   route: '01'
        // },

        // What is academic resilience?
        {
          name: 'What is academic resilience?',
          category: 'Toolkit post',
          route: '02'
        },

        // What does an academically resilient student look like?
        {
          name: 'What are some qualities of an academically tenacious student?',
          category: 'Toolkit post',
          route: '03'
        },

        // Can faculty help students foster academic resilience?
        {
          name: 'Can faculty help students foster academic resilience?',
          category: 'Toolkit post',
          route: '04'
        },

        // Academic resilience in the COVID context
        {
          name: 'Academic resilience in the COVID context',
          category: 'Blog post',
          route: '05'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '06'
        }
      ],

      // DISCUSS
      discuss: [
        // Why to discuss resilience with students
        {
          name: 'Why discuss resilience with students?',
          category: 'Toolkit post',
          route: '01'
        },

        // Five ways to discuss resilience with students
        {
          name: 'Five ways to discuss resilience with students',
          category: 'Toolkit post',
          route: '02'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '03'
        }
      ],

      // SHARE
      share: [
        // Resilience strategies
        {
          name: 'Resilience strategies',
          category: 'Presentation',
          route: '01'
        },

        // Activities that promote resilience
        {
          name: 'Promoting resilience with meditation',
          category: 'Podcast',
          route: '02'
        },

        // Exercise
        {
          name: 'The importance of exercise',
          category: 'Multiple',
          route: '03'
        },

        // Podcasts that discuss resilience
        {
          name: 'Podcasts that discuss resilience',
          category: 'Podcast',
          route: '04'
        },

        // Free tool helps post-secondary students improve mental well-being
        {
          name: 'Free tool helps post-secondary students improve mental well-being',
          category: 'Toolkit post',
          route: '05'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '06'
        }
      ],

      // DESIGN
      design: [
        // Do you have a hidden curriculum?
        {
          name: 'Do you have a hidden curriculum?',
          category: 'Toolkit post',
          route: '01'
        },

        // “Unhiding” a hidden curriculum
        {
          name: '“Unhiding” a hidden curriculum',
          category: 'Toolkit post',
          route: '02'
        },

        // Why should you create inclusive and brave learning spaces?
        {
          name: 'Why should you create inclusive and brave learning spaces?',
          category: 'Toolkit post',
          route: '03'
        },

        // Adopting resilient pedagogy
        {
          name: 'Adopting resilient pedagogy ',
          category: 'Website',
          route: '04'
        },

        // What is resilient course design?
        {
          name: 'What is resilient course design?',
          category: 'Website',
          route: '05'
        },

        // Providing quality feedback
        {
          name: 'Providing quality feedback',
          category: 'Multiple',
          route: '06'
        },

        // Incorporating resilient learning strategy and skill-building
        {
          name: 'Incorporating resilient learning strategy and skill-building',
          category: 'Report',
          route: '07'
        },

        // Helping students embrace and learn from failure
        {
          name: 'Helping students embrace and learn from failure',
          category: 'Toolkit post',
          route: '08'
        },

        // "The Holistic Learning Strategies Toolbox: Bringing in the Breath and the Body”
        {
          name: '“The Holistic Learning Strategies Toolbox: Bringing in the Breath and the Body”',
          category: 'Podcast',
          route: '09'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '10'
        }
      ],

      // REVIEW
      review: [
        // Blog posts
        {
          name: 'Blog posts',
          category: 'Toolkit post',
          route: '01'
        },

        // Journal articles
        {
          name: 'Journal articles',
          category: 'Toolkit post',
          route: '02'
        },

        // Magazine articles
        {
          name: 'Magazine articles',
          category: 'Toolkit post',
          route: '03'
        },

        // Videos
        {
          name: 'Videos',
          category: 'Toolkit post',
          route: '04'
        },

        // Book chapters
        {
          name: 'Book chapters ',
          category: 'Toolkit post',
          route: '05'
        },

        // Online resources
        {
          name: 'Online resources ',
          category: 'Toolkit post',
          route: '06'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '07'
        }
      ]
    },

    /**
     * Career DATA STORE
     */
    careerData: {
      // LEARN
      learn: [
        // What is career resilience?
        {
          name: 'What is career resilience?',
          category: 'Toolkit post',
          route: '01'
        },

        // How can we foster career resilience?
        {
          name: 'How can we foster career resilience?',
          category: 'Toolkit post',
          route: '02'
        },

        // Models of career resilience
        {
          name: 'Models of career resilience',
          category: 'Toolkit post',
          route: '03'
        },

        // Career resources for specific populations
        {
          name: 'Career resources for specific populations',
          category: 'Website',
          route: '04'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '05'
        }
      ],

      // DISCUSS
      discuss: [
        // “What does my headscarf mean to you?”
        {
          name: '“What does my headscarf mean to you?”',
          category: 'Video',
          route: '01'
        },

        // Preparing for the unexpected
        {
          name: 'Preparing for the unexpected',
          category: 'Journal article',
          route: '02'
        },

        // Linking students to viable, creative and innovative occupations
        {
          name: 'Linking students to viable, creative and innovative occupations',
          category: 'Report',
          route: '03'
        },

        // What not to do
        {
          name: 'What 𝘯𝘰𝘵 to do',
          category: 'Multiple',
          route: '04'
        },

        // What’s it really like out there?
        {
          name: 'What’s it really like out there?',
          category: 'Website',
          route: '05'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '06'
        }
      ],

      // SHARE
      share: [
        // What supports exist for students?
        {
          name: 'What supports exist for students?',
          category: 'Website',
          route: '01'
        },

        // Career Resilience Toolkit
        {
          name: 'Career Resilience Toolkit',
          category: 'Website',
          route: '02'
        },

        // Getting hired
        {
          name: 'Getting hired',
          category: 'Multiple',
          route: '03'
        },

        // Identifying transferable skills
        {
          name: 'Identifying transferable skills',
          category: 'Document',
          route: '04'
        },

        // The Career Resources Questionnaire
        {
          name: 'The Career Resources Questionnaire',
          category: 'Website',
          route: '05'
        },

        // Navigating career turbulence
        {
          name: 'Navigating career turbulence',
          category: 'Podcast',
          route: '06'
        },

        // Designing your life
        {
          name: 'Designing your life',
          category: 'Multiple',
          route: '07'
        },

        // Nine strategies to persist during your job search
        {
          name: 'Nine strategies to persist during your job search',
          category: 'Document',
          route: '08'
        },

        // “How Your Unique Story Can Get You Hired”
        {
          name: '“How Your Unique Story Can Get You Hired”',
          category: 'Video',
          route: '09'
        },

        // Supporting students with disabilities
        {
          name: 'Supporting students with disabilities',
          category: 'Document',
          route: '10'
        },

        // “What I Learned from 100 Days of Rejection”
        {
          name: '“What I Learned from 100 Days of Rejection”',
          category: 'Video',
          route: '11'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '12'
        }
      ],

      // DESIGN
      design: [
        // Incorporating career resilience into your curriculum
        {
          name: 'Incorporating career resilience into your curriculum',
          category: 'Toolkit post',
          route: '01'
        },

        // Enhancing students’ career resilience
        {
          name: 'Enhancing students’ career resilience',
          category: 'Report',
          route: '02'
        },

        // Curriculum toolkit
        {
          name: 'Curriculum toolkit',
          category: 'Website',
          route: '03'
        },

        // Embedding resilience in the career process
        {
          name: 'Embedding resilience in the career process',
          category: 'Report',
          route: '04'
        },

        // UN sustainable development goals and career development
        {
          name: 'UN sustainable development goals and career development',
          category: 'Multiple',
          route: '05'
        },

        // Work-integrated learning
        {
          name: 'Work-integrated learning',
          category: 'Website',
          route: '06'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '07'
        }
      ],

      // REVIEW
      review: [
        // Blog posts
        {
          name: 'Blog posts',
          category: 'Toolkit post',
          route: '01'
        },

        // Journal articles
        {
          name: 'Journal articles',
          category: 'Toolkit post',
          route: '02'
        },

        // Magazine articles
        {
          name: 'Magazine articles',
          category: 'Toolkit post',
          route: '03'
        },

        // Podcasts
        {
          name: 'Podcasts',
          category: 'Toolkit post',
          route: '04'
        },

        // Videos
        {
          name: 'Videos',
          category: 'Toolkit post',
          route: '05'
        },

        // Books
        {
          name: 'Books',
          category: 'Toolkit post',
          route: '06'
        },

        // Online resources
        {
          name: 'Online resources',
          category: 'Toolkit post',
          route: '07'
        },

        // Documents
        {
          name: 'Documents',
          category: 'Toolkit post',
          route: '08'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '09'
        }
      ]
    },

    /**
     * Community DATA STORE
     */
    communityData: {
      // LEARN
      learn: [
        // About community resilience
        {
          name: 'About community resilience',
          category: 'Toolkit post',
          route: '01'
        },

        // Ecological systems model for understanding individual and community resilience
        {
          name: 'Ecological systems model for understanding individual and community resilience',
          category: 'Podcast',
          route: '02'
        },

        // Indigenous resources
        {
          name: 'Indigenous resources',
          category: 'Toolkit post',
          route: '16'
        },

        // The importance of belonging
        {
          name: 'The importance of belonging',
          category: 'Toolkit post',
          route: '03'
        },

        // “Access Ain’t Inclusion”
        {
          name: '“Access Ain’t Inclusion”',
          category: 'Video',
          route: '04'
        },

        // Marginalized identities and intersectionality
        {
          name: 'Marginalized identities and intersectionality',
          category: 'Toolkit post',
          route: '05'
        },

        // About privilege
        {
          name: 'About privilege',
          category: 'Toolkit post',
          route: '06'
        },

        // About power
        {
          name: 'About power',
          category: 'Toolkit post',
          route: '07'
        },

        // About oppression
        {
          name: 'About oppression',
          category: 'Toolkit post',
          route: '08'
        },

        // What is intersectionality?
        {
          name: 'What is intersectionality?',
          category: 'Video',
          route: '09'
        },

        // Power, privilege and oppression: Pulling it all together
        {
          name: 'Power, privilege and oppression: Pulling it all together',
          category: 'Video',
          route: '10'
        },

        // Privilege and oppression binaries exercise
        {
          name: 'Privilege and oppression binaries exercise',
          category: 'Activity',
          route: '11'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '12'
        }
      ],

      // DISCUSS
      discuss: [
        // Supporting belonging
        {
          name: 'Supporting belonging',
          category: 'Magazine article',
          route: '01'
        },

        // Helping students thrive
        {
          name: 'Helping students thrive',
          category: 'Journal article',
          route: '02'
        },

        // Understanding the realities and needs of Indigenous learners
        {
          name: 'Understanding the realities and needs of Indigenous learners',
          category: 'Journal article',
          route: '10'
        },

        // Strategies for building a trauma-informed classroom community
        {
          name: 'Strategies for building a trauma-informed classroom community',
          category: 'Toolkit post',
          route: '03'
        },

        // Circles of control, influence and concern
        {
          name: 'Circles of control, influence and concern',
          category: 'Toolkit post',
          route: '04'
        },

        // Helping students in distress
        {
          name: 'Helping students in distress',
          category: 'Document',
          route: '05'
        },

        // Introducing controversial topics
        {
          name: 'Introducing controversial topics',
          category: 'Toolkit post',
          route: '06'
        },

        // Following up after a difficult conversation
        {
          name: 'Following up after a difficult conversation',
          category: 'Toolkit post',
          route: '07'
        },

        // Restorative justice on campuses
        {
          name: 'Restorative justice on campuses',
          category: 'Report',
          route: '08'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '09'
        }
      ],

      // SHARE
      share: [
        // Leading with Curiosity: Learning about identities you don’t hold
        {
          name: 'Leading with curiosity: Learning about identities you don’t hold',
          category: 'Toolkit post',
          route: '01'
        },

        // Building a curious, compassionate, and learning mindset
        {
          name: 'Building a curious, compassionate, and learning mindset',
          category: 'Toolkit post',
          route: '02'
        },

        // Sexual identity and orientation
        {
          name: 'Sexual identity and orientation',
          category: 'Toolkit post',
          route: '03'
        },

        // Gender identity
        {
          name: 'Gender identity',
          category: 'Toolkit post',
          route: '04'
        },

        // Black identity
        {
          name: 'Black identity',
          category: 'Toolkit post',
          route: '05'
        },

        // // Muslim identity and islamophobia
        // {
        //   name: 'Muslim identity and islamophobia',
        //   category: 'Toolkit post',
        //   route: '06'
        // },

        // // Jewish identity and antisemitism
        // {
        //   name: 'Jewish identity and antisemitism',
        //   category: 'Toolkit post',
        //   route: '07'
        // },

        // // Works cited
        // {
        //   name: 'Asian identity',
        //   category: 'Toolkit post',
        //   route: '08'
        // },
        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '09'
        }
      ],

      // DESIGN
      design: [
        // Overview: Teaching and learning strategies to promote belonging and wellbeing
        {
          name: 'Overview: Teaching and learning strategies to promote belonging and wellbeing',
          category: 'Multiple',
          route: '01'
        },

        // Setting learning community expectations
        {
          name: 'Setting learning community expectations',
          category: 'Toolkit post',
          route: '02'
        },

        // Positionality statements: What are they and why use them?
        {
          name: 'Positionality statements: What are they and why use them?',
          category: 'Toolkit post',
          route: '03'
        },

        // Considerations for declaring your positionality
        {
          name: 'Considerations for declaring your positionality',
          category: 'Toolkit post',
          route: '04'
        },

        // Create your own positionality statement
        {
          name: 'Create your own positionality statement',
          category: 'Toolkit post',
          route: '05'
        },

        // Benefits of a positionality statement
        {
          name: 'Benefits of a positionality statement',
          category: 'Toolkit post',
          route: '06'
        },

        // Positive Space Program
        {
          name: 'Positive Space Program',
          category: 'Presentation',
          route: '07'
        },

        // Review your syllabus and course content with an EDI lens
        {
          name: 'Review your syllabus and course content with an EDI lens',
          category: 'Toolkit post',
          route: '08'
        },

        // Integrate Universal Design for Learning Principles
        {
          name: 'Integrate Universal Design for Learning Principles',
          category: 'Toolkit post',
          route: '09'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '10'
        }
      ],

      // REVIEW
      review: [
        // Resilience within Indigenous communities
        {
          name: 'Resilience within Indigenous communities',
          category: 'Journal article',
          route: '12'
        },

        // Research behind disparities in mental health and impacts on learning
        {
          name: 'Research behind disparities in mental health and impacts on learning',
          category: 'Multiple',
          route: '01'
        },

        // Universal Design for Learning: Theory and Practice
        {
          name: 'Universal Design for Learning: Theory and Practice',
          category: 'Book',
          route: '02'
        },

        // Students’ sense of campus community: What it means, and what to do about it
        {
          name: 'Students’ sense of campus community: What it means, and what to do about it',
          category: 'Journal article',
          route: '03'
        },

        // Constructing belonging in a diverse campus community
        {
          name: 'Constructing belonging in a diverse campus community',
          category: 'Journal article',
          route: '04'
        },

        // Facilitating ‘Dialogues Across Difference’
        {
          name: 'Facilitating ‘Dialogues Across Difference’',
          category: 'Book',
          route: '05'
        },

        // Why do women opt out? Sense of belonging and women's representation in mathematics
        {
          name: 'Why do women opt out? Sense of belonging and women’s representation in mathematics',
          category: 'Journal article',
          route: '06'
        },

        // Sense of belonging and persistence in white and African-American first-year students
        {
          name: 'Sense of belonging and persistence in white and African-American first-year students',
          category: 'Journal article',
          route: '07'
        },

        // College students’ sense of belonging: Dimensions and correlates
        {
          name: 'College students’ sense of belonging: Dimensions and correlates',
          category: 'Journal article',
          route: '08'
        },

        // Learning environment, interaction, sense of belonging and study success in ethnically diverse student groups
        {
          name: 'Learning environment, interaction, sense of belonging and study success in ethnically diverse student groups',
          category: 'Journal article',
          route: '09'
        },

        // A brief social-belonging intervention improves academic and health outcomes of minority students
        {
          name: 'A brief social-belonging intervention improves academic and health outcomes of minority students',
          category: 'Journal article',
          route: '10'
        },

        // Works cited
        {
          name: 'Works cited',
          category: 'Toolkit post',
          route: '11'
        }
      ]
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
