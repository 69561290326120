<template>
  <!-- ACCORDION BASED OFF BS 5 ACCORDION -->
  <div class="accordion-item">
    <h3 class="accordion-header" id="headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#${accordionID}`" aria-expanded="true" :aria-controls="accordionID">
        {{headerText}}
      </button>
    </h3>
    <div :id="accordionID" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerText: {
      type: String,
      default: 'Heading Text'
    },
    accordionID: {
      type: String,
      default: 'collapseOne'
    }
  }
}
</script>

<style lang="scss">
  @import '../scss/colors';
  $accordion-icon-color: $dark-green;
  $accordion-icon-active-color: $dark-green;

  .accordion-item {
    // border-radius: 10px;
    // &:first-of-type {
    //   border-top-left-radius: 10px;
    //   border-top-right-radius: 10px;
    // }

    // BUTTON
    .accordion-button {
      color: #498111;
      font-size: 1.2rem;
      &:not(.collapsed) {
        color: #498111;
        background-color: $white;
        // box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
        &::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23498111'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }
      }

      &.collapsed::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23498111'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
  }

  .accordion-header {
  }

  .accordion-body {
    p:last-of-type,ul:last-of-type,li:last-of-type {
      margin-bottom: 0rem;
    }
  }

</style>
