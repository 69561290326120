<template>
  <div class="home home--landing">
    <nav class="navbar navbar-custom">
      <div class="container">
        <div>
          <img
            src="@/assets/img/_ui/svg/text-resize.svg"
            alt="resize text" srcset="" role="button" tabindex="0"
            @click="resizeText()"
          >
        </div>
        <!-- <a class="navbar-brand" href="#">Your students’ resilience</a> -->
        <router-link to="/"><img src="@/assets/img/_ui/svg/home.svg" alt="home" height="30px" srcset=""></router-link>
      </div>
    </nav>

    <!-- ROUTER CONTENT SHOULD GO HERE -->
    <div class="container">
      <div class="row">
        <div class="mt-4">
          <div class="landing mb-5">
            <h1>Thriving in the Classroom</h1>
            <p>A digital toolkit to support resilience in post-secondary educators and their students</p>
          </div>

          <!-- CONTENT -->
          <div class="content-wrapper mt-5">
            <div class="row">
              <div class="col-md-6">
                <h2>Hello there</h2>
                <p>Thank you for your interest in learning more about resilience.</p>
                <p>You’re joining thousands of college and university faculty, instructors and educators across the province who are committed to supporting themselves and their students in building and strengthening their resilience as they adapt to unpredictable and challenging conditions in the classroom and beyond.</p>
                <p>This toolkit shares the latest research, as well as skills and resources to empower you to promote personal, academic, community and career resilience in your students and yourself.</p>
              </div>
              <div class="col-md-4 offset-md-1 text-center">
                <h2>What is resilience?</h2>
                <p>Hear what others are saying about resilience</p>
                <button class="btn" @click="openPopup = true">
                  <img
                    src="@/assets/img/_content/home-poster.png"
                    class="img-fluid"
                    alt="Bio"
                  >
                </button>

                <router-link to="/problem-with-resilience" class="btn-nav btn-nav--orange">
                  The problem with "resilience"
                </router-link>
                <!-- <p><a :href="`${publicPath}docs/home/Acknowledgements.pdf`" target="_blank">Acknowledgements</a></p> -->
                <!-- <div class="video-player">
                  <div class="ratio ratio-16x9">
                    <iframe src="https://www.youtube.com/embed/NpEaa2P7qZI" title="YouTube video" allowfullscreen></iframe>
                  </div>
                  <div class="accordion">
                    <accordion
                      headerText="Transcript"
                      accordionID="transcript01"
                    >
                      <p>Descriptive transcript of video will appear here.</p>
                    </accordion>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <!-- CONTENT -->

          <!-- ROUTING NAV
          <div class="routing-nav">
            <h2>Whose resilience would you like to support today?</h2>
            <div>
              <div class="radio routing-nav__btn">
                <input
                  type="radio"
                  id="01-01"
                  value="own-resilience"
                  v-model="route01"
                  disabled
                >
                <label for="01-01">Your own resilience</label>
              </div>

              <div class="radio routing-nav__btn">
                <input
                  type="radio"
                  id="01-02"
                  value="students-resilience"
                  v-model="route01"
                  @click="scrollTo('#route02')"
                >
                <label for="01-02">Your students’ resilience</label>
              </div>
            </div>
          </div> -->

          <!-- ROUTING NAV
          <div class="routing-nav" id="route02" v-if="route01 != ''">
            <h2>How would you like to support your students today?</h2>
            <div>
              <div class="radio routing-nav__btn routing-nav__btn--large">
                <input
                  type="radio"
                  id="02-01"
                  value="learn"
                  v-model="route02"
                  @click="scrollTo('#route03')"
                >
                <label for="02-01"><strong>Learn</strong> about academic resilience</label>
              </div>

              <div class="radio routing-nav__btn routing-nav__btn--large">
                <input
                  type="radio"
                  id="02-02"
                  value="discuss"
                  v-model="route02"
                  @click="scrollTo('#route03')"
                >
                <label for="02-02"><strong>Discuss</strong> resilience with students</label>
              </div>

              <div class="radio routing-nav__btn routing-nav__btn--large">
                <input
                  type="radio"
                  id="02-03"
                  value="share"
                  v-model="route02"
                  @click="scrollTo('#route03')"
                >
                <label for="02-03"><strong>Share</strong> resources with students</label>
              </div>

              <div class="radio routing-nav__btn routing-nav__btn--large">
                <input
                  type="radio"
                  id="02-04"
                  value="design"
                  v-model="route02"
                  @click="scrollTo('#route03')"
                >
                <label for="02-04"><strong>Design</strong> curriculum that promotes resilience</label>
              </div>

              <div class="radio routing-nav__btn routing-nav__btn--large">
                <input
                  type="radio"
                  id="02-05"
                  value="review"
                  v-model="route02"
                  @click="scrollTo('#route03')"
                >
                <label for="02-05"><strong>Review</strong> research by resilience experts</label>
              </div>
            </div>
          </div> -->

          <!-- ROUTING NAV -->
          <div class="routing-nav" id="route03">
            <p>Resilience experts distinguish between four distinct kinds of resilience. </p>
            <h2>Which of these are you interested in today?</h2>
            <div>
            <router-link to="students-resilience/community" class="routing-nav__btn">
              <strong>Community</strong> resilience
            </router-link>
            <router-link to="students-resilience/personal" class="routing-nav__btn">
              <strong>Personal</strong> resilience
            </router-link>
            <router-link to="students-resilience/academic" class="routing-nav__btn">
              <strong>Academic</strong> resilience
            </router-link>
            <router-link to="students-resilience/career" class="routing-nav__btn">
             <strong>Career</strong> resilience
            </router-link>
              <!-- <div class="radio routing-nav__btn">
                <input
                  type="radio"
                  id="03-01"
                  value="community"
                  v-model="route03"
                  disabled
                >
                <label for="03-01"><strong>Community</strong> resilience</label>
              </div>

              <div class="radio routing-nav__btn">
                <input
                  type="radio"
                  id="03-02"
                  value="personal"
                  v-model="route03"
                >
                <label for="03-02"><strong>Personal</strong> resilience</label>
              </div>

              <div class="radio routing-nav__btn">
                <input
                  type="radio"
                  id="03-03"
                  value="academic"
                  v-model="route03"
                >
                <label for="03-03"><strong>Academic</strong> resilience</label>
              </div>

              <div class="radio routing-nav__btn">
                <input
                  type="radio"
                  id="03-04"
                  value="career"
                  v-model="route03"
                >
                <label for="03-04"><strong>Career</strong> resilience</label>
              </div> -->
            </div>
            <!-- <h3>Route: {{`/${route01}/${route03}/${route02}`}}</h3> -->
            <!-- <router-link class="btn-nav" :class="{disabled: route03 === ''}" tag="button" :disabled="route03 === ''" :to="`/${route01}/${route03}/${route02}`">Let's go</router-link> -->
          </div>
        </div>
      </div>

      <div class="landing" style="margin-top: 10rem;">
        <p><em>This project is made possible with funding by the Government of Ontario and through eCampusOntario’s support of the Virtual Learning Strategy</em></p>
        <img src="@/assets/img/_branding/ontario-rev.svg" alt="Ontario logo" class="img-fluid img-thumb d-inline-block mx-5" width="200px" srcset="">
        <img src="@/assets/img/_branding/ecampus-wht.svg" alt="ecampus logo" class="img-fluid img-thumb d-inline-block mx-5" width="180px" srcset="">
      </div>
    </div>

    <!-- MODAL -->
    <modal-popup v-if="openPopup" @close="openPopup = false" class="modal--large">
      <div class="video-player">
        <div class="ratio ratio-16x9">
          <iframe
            src="https://www.youtube.com/embed/wmJNYkeDx-k?rel=0&cc_load_policy=1"
            title="YouTube video"
            allowfullscreen
          ></iframe>
        </div>
        <div class="accordion">
          <accordion
            headerText="Transcript"
            accordionID="transcript01"
          >
            <p><strong>On-screen text: Here’s what a panel of resilience specialists had to say when we asked them… What does resilience mean to you?</strong> </p>
            <p><strong>Cecilia Amoakohene</strong>: In terms of what resilience means to me, I think that when I'm thinking about resilience, I'm thinking about how we both as individuals and as communities not only sort of survive but actually are able to succeed in the ways that we deem important. And I sort of think about adapting and bouncing back, not just to kind of make it through our day-to-day, but to actually bloom and grow in the spaces that we're in and be able to change our outlook based on the things that we've experienced and think about how as we're changing, there's also that interplay with our environment as well. I'm starting to question a little, as we grow and change, how are the systems that we are surviving and thriving within sort of growing and changing alongside us? And if they're not also questioning, why aren't they, and how can we use some of these experiences that we gained as we go through these moments that help us to build resilience, to, in turn sort of fine-tune that system so that it's moving alongside us as well.  </p>
            <p><strong>Deborah Chiodo</strong>: At one time, we used to think resilience was something you were born with. You either were resilient or you were not. And now we know that things like optimism, things like being happy, things like being mindful, those can really cultivate resilience in people. In so far as we are adults in the lives of students, you know, we have that opportunity to just show them how they can build their resilience because it's not static, it's this constant thing that's growing throughout our lives.  </p>
            <p><strong>Rick Ezekiel</strong>: What I get really excited about in terms of notions of resilience is we know the conditions that are either supportive or not really impacts the capacity of an individual to have resilient outcomes in the face of stress or adversity. So that gets me really excited to think about how as educators, as sort of people in positions of influence, we can create those conditions for our students when they might not exist in other places, in their lives and in our society to have more supportive relationships that increase ability to individually respond to a stressor. </p>
            <p><strong>Tracey Lloyd</strong>: I do think that our students come with a lot of resilience, what we like to term as resilience. But those conditions that we cultivate, foster, create in the classroom, I think, are very important. So it's not just about the individual—it's about the systems, the processes that support our students to be resilient.  </p>
            <p><strong>Deena Kara Shaffer</strong>: I try to think about etymology, if that's helpful. I try to think about how it's used now, including the ways that it's fraught. But like at the core, at the core, at the core, there's something around what is underneath the slings and arrows that we encounter in our life. That underneath, underneath, underneath, all that we encounter, that is so difficult and dispiriting, whether it is systemic, whether it is in our personal little local lives, all that can be so harmful what remains underneath. And from that underneath part, that steeliness, the part that's us, the part that's unchanging, no matter the weather, that doesn't get so weathered, that's the place that has some elasticity and some bounce sometimes that we can turn into, and so this work and interventions and toolkits and resources, I think, help shine a spotlight, help us shine our own spotlight on that place that's underneath, underneath, underneath. That place that has that springiness that we can, when we're ready and when we have capacity, and when there is spaciousness and when there's healing, that we can carry on in some kind of good way, that makes sense. So it's messy, but that's what resilience means to me.  </p>
            <p><strong>Nadine de Gannes</strong>: As an educator, when I think of resilience and what it means to me, I always think of it in the context of the students first, and I think of the world that exists for them, that's not visible to me. So I'm seeking to create a space in my classroom that I would describe as a space in which they can, they can flourish and thrive. But I don't know the challenges that they face in their personal lives. I don't know their histories and I won't know that until they share them with me or with each other.  </p>
            <p><strong>Becky Robinson</strong>: The other thing that comes up a lot when I talk to students is thinking that resilience means that I don't feel the pain, right, that I don't actually experience those low moments or those disappointments. And so really, I try to remind them that you'll still feel it; it's how you respond to that and that it's not easy, right? The idea of bouncing back makes it sound like it's an easy process, but that's a process that takes energy and work.  </p>
            <p><strong>Kate MacIsaac</strong>: To me, resilience is being able to persist with the resources and the wisdom that we have. Recognizing that that wisdom and those resources are ever-changing and the conditions that we are in are changing. And so with that resilience isn't linear. It's not like a horse out of the gate who just keeps going. Resilience is also knowing when to rest, knowing when to recharge and how to do that. And maybe recompose our resources and understand our systems, and sometimes resilience is knowing when to quit.</p>
            <p><strong>Diana Brecher</strong>: The classroom is a place of learning, and if we can clear the decks so that the students are free to learn and they're not feeling disconnected, or that they... Let's put it differently. An optimal circumstance is for students to feel that they can ask questions, that they can make mistakes, that they can learn, that whatever struggles that they have outside the classroom aren't interfering with their process of learning. So really, I think supporting resilience in the classroom, cultivating resilience in the classroom is really about facilitating the process of learning and it clears away obstacles.  </p>
            <p><strong>On-screen text</strong>: Thank you for joining us—Enjoy your exploration of this toolkit </p>
          </accordion>
        </div>
        <!-- <p><a :href="`${publicPath}docs/home/What-does-resilience-mean-to-you.pdf`" target="_blank">Open transcript in a new tab</a></p> -->
        <div class="p-3">
          <h3 class="">Acknowledgements</h3>
          <p>The work represented in the Thriving in the Classroom toolkit is based on the wisdom and experience generously shared by many individuals and groups.</p>
          <p>First, we would like to acknowledge that while this project was developed primarily virtually, project leadership took place from Centennial College, which sits on the traditional territory of the Mississaugas of the Credit First Nation, in the Dish with one Spoon treaty region. We express gratitude to Indigenous communities who continue to host and inspire this work. We would also like to thank Seán Kinsella (Centennial College) for their engagement and review of Indigenous content in this toolkit. We present this work recognizing our treaty obligations, and with deep commitment to truth and reconciliation as we build communities and relationships that foster our individual and collective resilience.</p>
          <p>We thank our content development leads, who offered their expertise, time, and skillful leadership in writing and curating the content you’ll find within this toolkit: Dr. Diana Brecher (Toronto Metropolitan University), Personal Resilience Lead; Dr. Deena Kara Shaffer (Toronto Metropolitan University), Academic Resilience Lead; Dr. Tracey Lloyd and Becky Robinson (Centennial College), Career Resilience Leads; and, Dr. Nadine de Gannes (Ivey School of Business, Western University), Kate MacIsaac (Trent University), and Cecilia Amoakohene (Centre for Innovation in Campus Mental Health, CICMH), Community Resilience Leads.</p>
          <p>We thank our original partners and their institutions/organizations for dedicating countless hours to the development of this toolkit, and active participation in our project advisory committee. Without each and every one of you, this toolkit would not have been possible: Jennifer Baytor (Western University), Ian Crookshank (Humber College), Dr. Sterling Crowe (Humber College), Dr. Erin Huner (Western University), Chantal Joy (Humber College), Dr. Rhonda Martinussen (OISE, University of Toronto), Emily Anne Opala (Centre for Innovation in Campus Mental Health, CICMH), Marija Padjen (Centre for Innovation in Campus Mental Health, CICMH), Chris Pankewich (Centre for Innovation in Campus Mental Health, CICMH, past employee), Nona Robinson (Trent University), and Leah State (Sheridan College).  The rich contributions of all these people have shaped this document in countless ways.</p>
          <p>We appreciate the generous administrative support from Centennial College and project coordination support from Elsie Goycoolea.</p>
          <p>We are grateful for the funding support from eCampus Ontario for making this toolkit a reality. We appreciate the vision that eCampus Ontario had when funding this project to ensure this toolkit is a resource that will be easily accessed and readily available to individuals across Ontario and beyond.</p>
          <p>We are indebted to the brilliant, talented, and experienced eLearning designers, thinkers, and developers at <a href="https://gevc.com/" target="_blank">GEVC</a> who took our ideas, learning experiences, and preferences, and created a masterful eLearning toolkit that exceeded our wildest imagination. You brought humour, enthusiasm, and great professionalism to the work within this toolkit.</p>
          <p>We thank faculty participants in our research project, including participants in a survey on barriers and facilitators to supporting student resilience in the classroom, and participants in recorded interviews, whose contributions informed the focus and materials captured within this toolkit.</p>
          <p>Finally, we thank all the students, faculty members, colleagues, friends and family, who have come into our lives and contributed to this toolkit in their own, special ways. Their energy, wisdom, and strength continue to inspire us each and every day. </p>
          <p>With sincere gratitude,</p>
          <p>Dr. Rick Ezekiel (Centennial College), Project Lead and Community Resilience Content Development Co-Lead</p>
          <p>Dr. Deborah Chiodo (Western University, Centre for Addiction and Mental Health), Project Implementation and Evaluation Lead</p>
        </div>
      </div>
    </modal-popup>
  </div>
</template>

<script>
import Accordion from '../components/Accordion.vue'
import ModalPopup from '../components/ModalPopup.vue'

export default {
  name: 'Landing',
  components: {
    Accordion,
    ModalPopup
  },

  data () {
    return {
      publicPath: process.env.BASE_URL,
      currentFontSize: 100,
      route01: '',
      route02: '',
      route03: '',

      openPopup: false
    }
  },

  methods: {
    resizeText () {
      const body = document.getElementsByTagName('html')[0]
      this.currentFontSize += 20
      if (this.currentFontSize >= 160) {
        this.currentFontSize = 100
      }

      body.style.fontSize = `${this.currentFontSize}%`
    },

    // Handle scrolling
    scrollTo (id) {
      setTimeout(() => {
        this.$scrollTo(id, { offset: -70 })
      }, 100)
      // VueScrollTo.scrollTo('#route01')
    }
  },

  mounted () {
  }
}
</script>
