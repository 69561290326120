import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  /*
  * SECTION 0.5 - The problem with “resilience”
  */
  {
    path: '/problem-with-resilience',
    name: 'The problem with “resilience”',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProblemWithResilience.vue')
  },

  /*
  * SECTION 1.0 - Your own resilience
  */
  {
    path: '/own-resilience',
    name: 'Your own resilience',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  /*
  * SECTION 2.0 - Your students’ resilience
  */
  {
    path: '/students-resilience',
    // name: 'Your students’ resilience',
    component: () => import(/* webpackChunkName: "about" */ '../views/StudentsResilience.vue'),

    children: [
      // 2.0 - HOME
      // {
      //   path: '',
      //   component: () => import('../views/students-resilience/academic/Learn.vue')
      // },

      // 2.1 - PERSONAL
      {
        path: 'personal',
        // redirect: 'personal/learn',
        component: () => import('../views/students-resilience/Personal.vue'),

        children: [
          // 2.2.0 - Landing
          {
            path: '',
            component: () => import('../views/students-resilience/personal/Landing.vue')
          },

          // 2.2.1 - LEARN
          {
            path: 'learn',
            component: () => import('../views/students-resilience/personal/Learn.vue'),

            children: [
              // 2.2.1.1 - Meet the expert
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/personal/learn/menu.vue')
              },

              // 2.2.1.1 - Meet the expert
              // {
              //   path: '01',
              //   component: () => import('../views/students-resilience/personal/learn/01.vue')
              // },

              // 2.2.1.2 - What is personal resilience
              {
                path: '02',
                component: () => import('../views/students-resilience/personal/learn/02.vue')
              },

              // 2.2.1.3 - What does an personalally resilient student look like
              {
                path: '03',
                component: () => import('../views/students-resilience/personal/learn/03.vue')
              },

              // 2.2.1.4 - Can faculty help students foster personal resilience
              {
                path: '04',
                component: () => import('../views/students-resilience/personal/learn/04.vue')
              },

              // 2.2.1.5 - personal resilience in the COVID context
              {
                path: '05',
                component: () => import('../views/students-resilience/personal/learn/05.vue')
              },

              // 2.2.1.6 - Works cited
              {
                path: '06',
                component: () => import('../views/students-resilience/personal/learn/06.vue')
              }
            ]
          },

          // 2.2.2 - DISCUSS
          {
            path: 'discuss',
            component: () => import('../views/students-resilience/personal/Discuss.vue'),

            children: [
              // 2.2.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/personal/discuss/menu.vue')
              },

              // 2.2.2.1 - Why to discuss resilience with students
              {
                path: '01',
                component: () => import('../views/students-resilience/personal/discuss/01.vue')
              },

              // 2.2.2.2 - Five ways to discuss resilience with students
              {
                path: '02',
                component: () => import('../views/students-resilience/personal/discuss/02.vue')
              },

              // 2.2.2.3 - Works cited
              {
                path: '03',
                component: () => import('../views/students-resilience/personal/discuss/03.vue')
              },

              // 2.2.2.4 - Works cited
              {
                path: '04',
                component: () => import('../views/students-resilience/personal/discuss/04.vue')
              }
            ]
          },

          // 2.2.3 - SHARE
          {
            path: 'share',
            component: () => import('../views/students-resilience/personal/Share.vue'),

            children: [
              // 2.2.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/personal/share/menu.vue')
              },

              // 2.2.3.1 - Resilience strategies
              {
                path: '01',
                component: () => import('../views/students-resilience/personal/share/01.vue')
              },

              // 2.2.3.2 - Activities that promote resilience
              {
                path: '02',
                component: () => import('../views/students-resilience/personal/share/02.vue')
              },

              // 2.2.3.3 - Exercise
              {
                path: '03',
                component: () => import('../views/students-resilience/personal/share/03.vue')
              },

              // 2.2.3.4 - Podcasts that discuss resilience
              {
                path: '04',
                component: () => import('../views/students-resilience/personal/share/04.vue')
              },

              // 2.2.3.5 - Works cited
              {
                path: '05',
                component: () => import('../views/students-resilience/personal/share/05.vue')
              },

              // 2.2.3.6 - Works cited
              {
                path: '06',
                component: () => import('../views/students-resilience/personal/share/06.vue')
              },

              // 2.2.3.7 - Works cited
              // {
              //   path: '07',
              //   component: () => import('../views/students-resilience/personal/share/07.vue')
              // },

              // 2.2.3.8 - Works cited
              {
                path: '08',
                component: () => import('../views/students-resilience/personal/share/08.vue')
              },

              // 2.2.3.9 - Works cited
              {
                path: '09',
                component: () => import('../views/students-resilience/personal/share/09.vue')
              }
            ]
          },

          // 2.2.4 - DESIGN
          {
            path: 'design',
            component: () => import('../views/students-resilience/personal/Design.vue'),

            children: [
              // 2.2.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/personal/design/menu.vue')
              },

              // 2.2.4.1 - Resilience strategies
              {
                path: '01',
                component: () => import('../views/students-resilience/personal/design/01.vue')
              },

              // 2.2.4.2 - Activities that promote resilience
              {
                path: '02',
                component: () => import('../views/students-resilience/personal/design/02.vue')
              },

              // 2.2.4.3 - Exercise
              {
                path: '03',
                component: () => import('../views/students-resilience/personal/design/03.vue')
              }
            ]
          },

          // 2.2.5 - REVIEW
          {
            path: 'review',
            component: () => import('../views/students-resilience/personal/Review.vue'),

            children: [
              // 2.2.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/personal/review/menu.vue')
              },

              // 2.2.5.1 - Online resources
              {
                path: '01',
                component: () => import('../views/students-resilience/personal/review/01.vue')
              },

              // 2.2.5.2 - Blog posts
              {
                path: '02',
                component: () => import('../views/students-resilience/personal/review/02.vue')
              },

              // 2.2.5.3 - Journal articles
              {
                path: '03',
                component: () => import('../views/students-resilience/personal/review/03.vue')
              },

              // 2.2.5.4 - Magazine articles
              {
                path: '04',
                component: () => import('../views/students-resilience/personal/review/04.vue')
              },
              // 2.2.5.4 - Magazine articles
              {
                path: '05',
                component: () => import('../views/students-resilience/personal/review/05.vue')
              },
              // 2.2.5.4 - Magazine articles
              {
                path: '06',
                component: () => import('../views/students-resilience/personal/review/06.vue')
              },
              // 2.2.5.4 - Magazine articles
              {
                path: '07',
                component: () => import('../views/students-resilience/personal/review/07.vue')
              },
              // 2.2.5.4 - Magazine articles
              {
                path: '08',
                component: () => import('../views/students-resilience/personal/review/08.vue')
              }
            ]
          }
        ]
      },

      // 2.2 - ACADEMIC
      {
        path: 'academic',
        // redirect: 'academic/learn',
        component: () => import('../views/students-resilience/Academic.vue'),

        children: [
          // 2.2.0 - Landing
          {
            path: '',
            component: () => import('../views/students-resilience/academic/Landing.vue')
          },

          // 2.2.1 - LEARN
          {
            path: 'learn',
            component: () => import('../views/students-resilience/academic/Learn.vue'),

            children: [
              // 2.2.1.1 - Meet the expert
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/academic/learn/menu.vue')
              },

              // 2.2.1.1 - Meet the expert
              // {
              //   path: '01',
              //   component: () => import('../views/students-resilience/academic/learn/01.vue')
              // },

              // 2.2.1.2 - What is academic resilience
              {
                path: '02',
                component: () => import('../views/students-resilience/academic/learn/02.vue')
              },

              // 2.2.1.3 - What does an academically resilient student look like
              {
                path: '03',
                component: () => import('../views/students-resilience/academic/learn/03.vue')
              },

              // 2.2.1.4 - Can faculty help students foster academic resilience
              {
                path: '04',
                component: () => import('../views/students-resilience/academic/learn/04.vue')
              },

              // 2.2.1.5 - Academic resilience in the COVID context
              {
                path: '05',
                component: () => import('../views/students-resilience/academic/learn/05.vue')
              },

              // 2.2.1.6 - Works cited
              {
                path: '06',
                component: () => import('../views/students-resilience/academic/learn/06.vue')
              }
            ]
          },

          // 2.2.2 - DISCUSS
          {
            path: 'discuss',
            component: () => import('../views/students-resilience/academic/Discuss.vue'),

            children: [
              // 2.2.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/academic/discuss/menu.vue')
              },

              // 2.2.2.1 - Why to discuss resilience with students
              {
                path: '01',
                component: () => import('../views/students-resilience/academic/discuss/01.vue')
              },

              // 2.2.2.2 - Five ways to discuss resilience with students
              {
                path: '02',
                component: () => import('../views/students-resilience/academic/discuss/02.vue')
              },

              // 2.2.2.3 - Works cited
              {
                path: '03',
                component: () => import('../views/students-resilience/academic/discuss/03.vue')
              }
            ]
          },

          // 2.2.3 - SHARE
          {
            path: 'share',
            component: () => import('../views/students-resilience/academic/Share.vue'),

            children: [
              // 2.2.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/academic/share/menu.vue')
              },

              // 2.2.3.1 - Resilience strategies
              {
                path: '01',
                component: () => import('../views/students-resilience/academic/share/01.vue')
              },

              // 2.2.3.2 - Activities that promote resilience
              {
                path: '02',
                component: () => import('../views/students-resilience/academic/share/02.vue')
              },

              // 2.2.3.3 - Exercise
              {
                path: '03',
                component: () => import('../views/students-resilience/academic/share/03.vue')
              },

              // 2.2.3.4 - Podcasts that discuss resilience
              {
                path: '04',
                component: () => import('../views/students-resilience/academic/share/04.vue')
              },

              // 2.2.3.5 - Free tool helps post-secondary students improve mental well-being
              {
                path: '05',
                component: () => import('../views/students-resilience/academic/share/05.vue')
              },

              // 2.2.3.6 - Works cited
              {
                path: '06',
                component: () => import('../views/students-resilience/academic/share/06.vue')
              }
            ]
          },

          // 2.2.4 - DESIGN
          {
            path: 'design',
            component: () => import('../views/students-resilience/academic/Design.vue'),

            children: [
              // 2.2.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/academic/design/menu.vue')
              },

              // 2.2.4.1 - Resilience strategies
              {
                path: '01',
                component: () => import('../views/students-resilience/academic/design/01.vue')
              },

              // 2.2.4.2 - Activities that promote resilience
              {
                path: '02',
                component: () => import('../views/students-resilience/academic/design/02.vue')
              },

              // 2.2.4.3 - Exercise
              {
                path: '03',
                component: () => import('../views/students-resilience/academic/design/03.vue')
              },

              // 2.2.4.4 - Podcasts that discuss resilience
              {
                path: '04',
                component: () => import('../views/students-resilience/academic/design/04.vue')
              },

              // 2.2.4.5 - Works cited
              {
                path: '05',
                component: () => import('../views/students-resilience/academic/design/05.vue')
              },

              // 2.2.4.6 - Resilience strategies
              {
                path: '06',
                component: () => import('../views/students-resilience/academic/design/06.vue')
              },

              // 2.2.4.7 - Activities that promote resilience
              {
                path: '07',
                component: () => import('../views/students-resilience/academic/design/07.vue')
              },

              // 2.2.4.8 - Help students embrace and learn from failure
              {
                path: '08',
                component: () => import('../views/students-resilience/academic/design/08.vue')
              },

              // 2.2.4.9 - Podcasts that discuss resilience
              {
                path: '09',
                component: () => import('../views/students-resilience/academic/design/09.vue')
              },

              // 2.2.4.10 - Works cited
              {
                path: '10',
                component: () => import('../views/students-resilience/academic/design/10.vue')
              }
            ]
          },

          // 2.2.5 - REVIEW
          {
            path: 'review',
            component: () => import('../views/students-resilience/academic/Review.vue'),

            children: [
              // 2.2.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/academic/review/menu.vue')
              },

              // 2.2.5.1 - Online resources
              {
                path: '01',
                component: () => import('../views/students-resilience/academic/review/01.vue')
              },

              // 2.2.5.2 - Blog posts
              {
                path: '02',
                component: () => import('../views/students-resilience/academic/review/02.vue')
              },

              // 2.2.5.3 - Journal articles
              {
                path: '03',
                component: () => import('../views/students-resilience/academic/review/03.vue')
              },

              // 2.2.5.4 - Magazine articles
              {
                path: '04',
                component: () => import('../views/students-resilience/academic/review/04.vue')
              },

              // 2.2.5.5 - Videos
              {
                path: '05',
                component: () => import('../views/students-resilience/academic/review/05.vue')
              },

              // 2.2.5.6 - Book chapters
              {
                path: '06',
                component: () => import('../views/students-resilience/academic/review/06.vue')
              },

              // 2.2.5.7 - Online resources
              {
                path: '07',
                component: () => import('../views/students-resilience/academic/review/07.vue')
              }
            ]
          }
        ]
      },

      // 2.3 - Career
      {
        path: 'career',
        redirect: 'career/learn',
        component: () => import('../views/students-resilience/Career.vue'),

        children: [
          // 2.3.0 - Landing
          {
            path: '',
            component: () => import('../views/students-resilience/career/Landing.vue')
          },

          // 2.3.1 - LEARN
          {
            path: 'learn',
            component: () => import('../views/students-resilience/career/Learn.vue'),

            children: [
              // 2.3.1.1
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/career/learn/menu.vue')
              },

              // 2.3.1.1
              {
                path: '01',
                component: () => import('../views/students-resilience/career/learn/01.vue')
              },

              // 2.3.1.2
              {
                path: '02',
                component: () => import('../views/students-resilience/career/learn/02.vue')
              },

              // 2.3.1.3
              {
                path: '03',
                component: () => import('../views/students-resilience/career/learn/03.vue')
              },

              // 2.3.1.4
              {
                path: '04',
                component: () => import('../views/students-resilience/career/learn/04.vue')
              },

              // 2.3.1.5
              {
                path: '05',
                component: () => import('../views/students-resilience/career/learn/05.vue')
              }
            ]
          },

          // 2.3.2 - DISCUSS
          {
            path: 'discuss',
            component: () => import('../views/students-resilience/career/Discuss.vue'),

            children: [
              // 2.3.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/career/discuss/menu.vue')
              },

              // 2.3.2.1
              {
                path: '01',
                component: () => import('../views/students-resilience/career/discuss/01.vue')
              },

              // 2.3.2.2
              {
                path: '02',
                component: () => import('../views/students-resilience/career/discuss/02.vue')
              },

              // 2.3.2.3
              {
                path: '03',
                component: () => import('../views/students-resilience/career/discuss/03.vue')
              },

              // 2.3.2.4
              {
                path: '04',
                component: () => import('../views/students-resilience/career/discuss/04.vue')
              },

              // 2.3.2.5
              {
                path: '05',
                component: () => import('../views/students-resilience/career/discuss/05.vue')
              },

              // 2.3.2.6
              {
                path: '06',
                component: () => import('../views/students-resilience/career/discuss/06.vue')
              }
            ]
          },

          // 2.3.3 - SHARE
          {
            path: 'share',
            component: () => import('../views/students-resilience/career/Share.vue'),

            children: [
              // 2.3.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/career/share/menu.vue')
              },

              // 2.3.3.1
              {
                path: '01',
                component: () => import('../views/students-resilience/career/share/01.vue')
              },

              // 2.3.3.2
              {
                path: '02',
                component: () => import('../views/students-resilience/career/share/02.vue')
              },

              // 2.3.3.3
              {
                path: '03',
                component: () => import('../views/students-resilience/career/share/03.vue')
              },

              // 2.3.3.4
              {
                path: '04',
                component: () => import('../views/students-resilience/career/share/04.vue')
              },

              // 2.3.3.5
              {
                path: '05',
                component: () => import('../views/students-resilience/career/share/05.vue')
              },

              // 2.3.3.6
              {
                path: '06',
                component: () => import('../views/students-resilience/career/share/06.vue')
              },

              // 2.3.3.7
              {
                path: '07',
                component: () => import('../views/students-resilience/career/share/07.vue')
              },

              // 2.3.3.8
              {
                path: '08',
                component: () => import('../views/students-resilience/career/share/08.vue')
              },

              // 2.3.3.9
              {
                path: '09',
                component: () => import('../views/students-resilience/career/share/09.vue')
              },

              // 2.3.3.10
              {
                path: '10',
                component: () => import('../views/students-resilience/career/share/10.vue')
              },

              // 2.3.3.11
              {
                path: '11',
                component: () => import('../views/students-resilience/career/share/11.vue')
              },

              // 2.3.3.12
              {
                path: '12',
                component: () => import('../views/students-resilience/career/share/12.vue')
              }
            ]
          },

          // 2.3.4 - DESIGN
          {
            path: 'design',
            component: () => import('../views/students-resilience/career/Design.vue'),

            children: [
              // 2.3.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/career/design/menu.vue')
              },

              // 2.3.4.1
              {
                path: '01',
                component: () => import('../views/students-resilience/career/design/01.vue')
              },

              // 2.3.4.2
              {
                path: '02',
                component: () => import('../views/students-resilience/career/design/02.vue')
              },

              // 2.3.4.3
              {
                path: '03',
                component: () => import('../views/students-resilience/career/design/03.vue')
              },

              // 2.3.4.4
              {
                path: '04',
                component: () => import('../views/students-resilience/career/design/04.vue')
              },

              // 2.3.4.5
              {
                path: '05',
                component: () => import('../views/students-resilience/career/design/05.vue')
              },

              // 2.3.4.6
              {
                path: '06',
                component: () => import('../views/students-resilience/career/design/06.vue')
              },

              // 2.3.4.7
              {
                path: '07',
                component: () => import('../views/students-resilience/career/design/07.vue')
              }
            ]
          },

          // 2.3.5 - REVIEW
          {
            path: 'review',
            component: () => import('../views/students-resilience/career/Review.vue'),

            children: [
              // 2.3.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/career/review/menu.vue')
              },

              // 2.3.5.1
              {
                path: '01',
                component: () => import('../views/students-resilience/career/review/01.vue')
              },

              // 2.3.5.2
              {
                path: '02',
                component: () => import('../views/students-resilience/career/review/02.vue')
              },

              // 2.3.5.3
              {
                path: '03',
                component: () => import('../views/students-resilience/career/review/03.vue')
              },

              // 2.3.5.4
              {
                path: '04',
                component: () => import('../views/students-resilience/career/review/04.vue')
              },

              // 2.3.5.5
              {
                path: '05',
                component: () => import('../views/students-resilience/career/review/05.vue')
              },

              // 2.3.5.6
              {
                path: '06',
                component: () => import('../views/students-resilience/career/review/06.vue')
              },

              // 2.3.5.7
              {
                path: '07',
                component: () => import('../views/students-resilience/career/review/07.vue')
              },

              // 2.3.5.8
              {
                path: '08',
                component: () => import('../views/students-resilience/career/review/08.vue')
              },

              // 2.3.5.9
              {
                path: '09',
                component: () => import('../views/students-resilience/career/review/09.vue')
              }
            ]
          }
        ]
      },

      // 2.4 - Community
      {
        path: 'community',
        redirect: 'community/learn',
        component: () => import('../views/students-resilience/Community.vue'),

        children: [
          // 2.4.0 - Landing
          {
            path: '',
            component: () => import('../views/students-resilience/community/Landing.vue')
          },

          // 2.4.1 - LEARN
          {
            path: 'learn',
            component: () => import('../views/students-resilience/community/Learn.vue'),

            children: [
              // 2.4.1.1
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/community/learn/menu.vue')
              },

              // 2.4.1.1
              {
                path: '01',
                component: () => import('../views/students-resilience/community/learn/01.vue')
              },

              // 2.4.1.2
              {
                path: '02',
                component: () => import('../views/students-resilience/community/learn/02.vue')
              },

              // 2.4.1.2
              {
                path: '16',
                component: () => import('../views/students-resilience/community/learn/16.vue')
              },

              // 2.4.1.3
              {
                path: '03',
                component: () => import('../views/students-resilience/community/learn/03.vue')
              },

              // 2.4.1.4
              {
                path: '04',
                component: () => import('../views/students-resilience/community/learn/04.vue')
              },

              // 2.4.1.5
              {
                path: '05',
                component: () => import('../views/students-resilience/community/learn/05.vue')
              },

              // 2.4.1.6
              {
                path: '06',
                component: () => import('../views/students-resilience/community/learn/06.vue')
              },

              // 2.4.1.7
              {
                path: '07',
                component: () => import('../views/students-resilience/community/learn/07.vue')
              },

              // 2.4.1.8
              {
                path: '08',
                component: () => import('../views/students-resilience/community/learn/08.vue')
              },

              // 2.4.1.9
              {
                path: '09',
                component: () => import('../views/students-resilience/community/learn/09.vue')
              },

              // 2.4.1.10
              {
                path: '10',
                component: () => import('../views/students-resilience/community/learn/10.vue')
              },

              // 2.4.1.11
              {
                path: '11',
                component: () => import('../views/students-resilience/community/learn/11.vue')
              },

              // 2.4.1.12
              {
                path: '12',
                component: () => import('../views/students-resilience/community/learn/12.vue')
              },

              // 2.4.1.11
              {
                path: '11',
                component: () => import('../views/students-resilience/community/learn/11.vue')
              },

              // 2.4.1.12
              {
                path: '12',
                component: () => import('../views/students-resilience/community/learn/12.vue')
              }
            ]
          },

          // 2.4.2 - DISCUSS
          {
            path: 'discuss',
            component: () => import('../views/students-resilience/community/Discuss.vue'),

            children: [
              // 2.4.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/community/discuss/menu.vue')
              },

              // 2.4.2.1
              {
                path: '01',
                component: () => import('../views/students-resilience/community/discuss/01.vue')
              },

              // 2.4.2.2
              {
                path: '02',
                component: () => import('../views/students-resilience/community/discuss/02.vue')
              },

              // 2.4.2.2
              {
                path: '10',
                component: () => import('../views/students-resilience/community/discuss/10.vue')
              },

              // 2.4.2.3
              {
                path: '03',
                component: () => import('../views/students-resilience/community/discuss/03.vue')
              },

              // 2.4.2.4
              {
                path: '04',
                component: () => import('../views/students-resilience/community/discuss/04.vue')
              },

              // 2.4.2.5
              {
                path: '05',
                component: () => import('../views/students-resilience/community/discuss/05.vue')
              },

              // 2.4.2.6
              {
                path: '06',
                component: () => import('../views/students-resilience/community/discuss/06.vue')
              },

              // 2.4.2.7
              {
                path: '07',
                component: () => import('../views/students-resilience/community/discuss/07.vue')
              },

              // 2.4.2.8
              {
                path: '08',
                component: () => import('../views/students-resilience/community/discuss/08.vue')
              },
              // 2.4.2.8
              {
                path: '09',
                component: () => import('../views/students-resilience/community/discuss/09.vue')
              }
            ]
          },

          // 2.4.3 - SHARE
          {
            path: 'share',
            component: () => import('../views/students-resilience/community/Share.vue'),

            children: [
              // 2.4.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/community/share/menu.vue')
              },

              // 2.4.3.1
              {
                path: '01',
                component: () => import('../views/students-resilience/community/share/01.vue')
              },

              // 2.4.3.2
              {
                path: '02',
                component: () => import('../views/students-resilience/community/share/02.vue')
              },

              // 2.4.3.3
              {
                path: '03',
                component: () => import('../views/students-resilience/community/share/03.vue')
              },

              // 2.4.3.4
              {
                path: '04',
                component: () => import('../views/students-resilience/community/share/04.vue')
              },

              // 2.4.3.5
              {
                path: '05',
                component: () => import('../views/students-resilience/community/share/05.vue')
              },

              // 2.4.3.6
              {
                path: '06',
                component: () => import('../views/students-resilience/community/share/06.vue')
              },

              // 2.4.3.7
              {
                path: '07',
                component: () => import('../views/students-resilience/community/share/07.vue')
              },

              // 2.4.3.8
              {
                path: '08',
                component: () => import('../views/students-resilience/community/share/08.vue')
              },
              // 2.4.3.9
              {
                path: '09',
                component: () => import('../views/students-resilience/community/share/09.vue')
              }
            ]
          },

          // 2.4.4 - DESIGN
          {
            path: 'design',
            component: () => import('../views/students-resilience/community/Design.vue'),

            children: [
              // 2.4.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/community/design/menu.vue')
              },

              // 2.4.4.1
              {
                path: '01',
                component: () => import('../views/students-resilience/community/design/01.vue')
              },

              // 2.4.4.2
              {
                path: '02',
                component: () => import('../views/students-resilience/community/design/02.vue')
              },

              // 2.4.4.3
              {
                path: '03',
                component: () => import('../views/students-resilience/community/design/03.vue')
              },

              // 2.4.4.4
              {
                path: '04',
                component: () => import('../views/students-resilience/community/design/04.vue')
              },

              // 2.4.4.5
              {
                path: '05',
                component: () => import('../views/students-resilience/community/design/05.vue')
              },

              // 2.4.4.6
              {
                path: '06',
                component: () => import('../views/students-resilience/community/design/06.vue')
              },

              // 2.4.4.7
              {
                path: '07',
                component: () => import('../views/students-resilience/community/design/07.vue')
              },

              // 2.4.4.8
              {
                path: '08',
                component: () => import('../views/students-resilience/community/design/08.vue')
              },

              // 2.4.4.9
              {
                path: '09',
                component: () => import('../views/students-resilience/community/design/09.vue')
              },

              // 2.4.4.10
              {
                path: '10',
                component: () => import('../views/students-resilience/community/design/10.vue')
              }
            ]
          },

          // 2.4.5 - REVIEW
          {
            path: 'review',
            component: () => import('../views/students-resilience/community/Review.vue'),

            children: [
              // 2.4.2.0 - Menu
              {
                path: '',
                name: 'menu',
                component: () => import('../views/students-resilience/community/review/menu.vue')
              },

              // 2.4.5.1
              {
                path: '12',
                component: () => import('../views/students-resilience/community/review/12.vue')
              },

              // 2.4.5.1
              {
                path: '01',
                component: () => import('../views/students-resilience/community/review/01.vue')
              },

              // 2.4.5.2
              {
                path: '02',
                component: () => import('../views/students-resilience/community/review/02.vue')
              },

              // 2.4.5.3
              {
                path: '03',
                component: () => import('../views/students-resilience/community/review/03.vue')
              },

              // 2.4.5.4
              {
                path: '04',
                component: () => import('../views/students-resilience/community/review/04.vue')
              },

              // 2.4.5.5
              {
                path: '05',
                component: () => import('../views/students-resilience/community/review/05.vue')
              },

              // 2.4.5.6
              {
                path: '06',
                component: () => import('../views/students-resilience/community/review/06.vue')
              },

              // 2.4.5.7
              {
                path: '07',
                component: () => import('../views/students-resilience/community/review/07.vue')
              },

              // 2.4.5.8
              {
                path: '08',
                component: () => import('../views/students-resilience/community/review/08.vue')
              },

              // 2.4.5.9
              {
                path: '09',
                component: () => import('../views/students-resilience/community/review/09.vue')
              },

              // 2.4.5.10
              {
                path: '10',
                component: () => import('../views/students-resilience/community/review/10.vue')
              },

              // 2.4.5.11
              {
                path: '11',
                component: () => import('../views/students-resilience/community/review/11.vue')
              }
            ]
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
