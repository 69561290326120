<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <!-- <div class="modal-close">
          <button class="modal-default-button" @click="$emit('close')">
            <img
              src="@/assets/img/_nav/close-icon-org.png"
              class="img-fluid"
              alt="close"
            />
          </button>
        </div> -->
        <div class="modal-container">
          <slot></slot>

          <div class="modal__footer">
            <slot name="footer">
              <button class="modal-default-button" aria-label="close" @click="$emit('close')">
                X
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalPopup',
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../scss/colors';

.modal--large {
  .modal-container {
    max-width: 90%;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 2rem;
}

.modal-container {
  // max-width: 90vw;
  max-width: 55%;
  min-width: 20rem;
  max-height: 95vh;
  overflow: auto;
  margin: 0px auto;
  // padding: 20px 30px;
  background-color: #fff;
  // color: #000;
  // text-align: center;
  // border:3px solid #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  /* font-family: Avenir, Arial, sans-serif; */
  // font-weight: 700;
  position: relative;
  padding: 2rem;
  font-size: 0.8rem;
  outline: 1px solid #fff;
  outline-offset: -10px;
}

.modal-header h3 {
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  color: #000;
  cursor: pointer;
  z-index: 1030;
  font-weight: 700;
  // background-color: $charcoal;
  // border: 2px solid $blue;
  padding: .5rem 1rem;
  border: none;
  background: none;
}

.modal__footer {
  border-bottom: 0px;
  margin-top: 2rem;

  position: absolute;
  top: -32px;
  right: 0px;

}

/*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

img {
  display: block;
  margin: 1rem auto;
}

/*------------------------------------*\
  #MEDIA SCREENS > 767px (TABLETS)
\*------------------------------------*/
@media (min-width: 47.93em) {
  .modal-container {
    // width: 70%;
    font-size: 1rem;
  }
}

/*------------------------------------*\
  #MEDIA SCREENS > 992px
  (LAPTOP, TABLET)
\*------------------------------------*/
@media (min-width: 992px) {
  .modal-container {
    // width: 50%;
  }
}
/*------------------------------------*\
  #MEDIA SCREENS > 1200px (DESKTOP)
\*------------------------------------*/
@media (min-width: 75em) {
}
/*------------------------------------*\
  #MEDIA SCREENS > 1320px (DESKTOP)
\*------------------------------------*/
@media (min-width: 1320px) {
}
/*------------------------------------*\
  #MEDIA SCREENS > 1600px (DESKTOP)
\*------------------------------------*/
@media (min-width: 1600px) {
}
</style>
